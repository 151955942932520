/* eslint-disable no-param-reassign */
import Axios from 'axios';
import queryString from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getErrorMessage } from 'releox-react';
import SimplePeer, { SignalData } from 'simple-peer';
import * as yup from 'yup';
import { LogMessages } from './components/LogMessages';
import apis from './configs/apis';
import { ReduxDispatch } from './store/ActionInterfaces';
import Command from './store/command/Command';
import CommandsAction from './store/command/CommandsAction';
import CommandsSelector from './store/command/CommandsSelector';
import LogsAction from './store/log/LogsAction';
import StreamAction from './store/stream/StreamAction';
import { StreamDetails } from './StremDetails';

interface Peer {
  isAnswered: boolean;
  peer: SimplePeer.Instance;
}

const peerClient: Peer = {
  isAnswered: false,
  peer: {} as SimplePeer.Instance,
};

let mediaStream: MediaStream;
const inDevMode = false;

type PeerConnectionType = 'init' | 'notInit';

export const ClientScene = (): JSX.Element => {
  const dispatch = useDispatch<ReduxDispatch>();
  const [currentAudioConstraints, setCurrentAudioConstraints] = useState<
    MediaTrackConstraints | undefined
  >();
  const [currentAudioSettings, setCurrentAudioSettings] = useState<
    MediaTrackSettings | undefined
  >();
  const [currentAudioCapabilities, setCurrentAudioCapabilities] = useState<
    MediaTrackCapabilities | undefined
  >();
  const [blockError, setBlockError] = useState('');
  const remoteVideoRef = useRef<HTMLVideoElement>(null);
  const location = useLocation();
  const command = useSelector(CommandsSelector.selectCommand);

  const onPeerStream = useCallback(
    (remoteStream: MediaStream): void => {
      dispatch(StreamAction.playVideo(remoteVideoRef, remoteStream));
    },
    [dispatch, remoteVideoRef]
  );

  const initPeerConnection = useCallback(
    (type: PeerConnectionType, stream: MediaStream): SimplePeer.Instance => {
      const peer = new SimplePeer({
        initiator: type === 'init',
        stream,
        trickle: false,
      });
      peer.on('stream', onPeerStream);
      return peer;
    },
    [onPeerStream]
  );

  const openVideoStream = useCallback((): void => {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          autoGainControl: true,
        },
        video: false,
      })
      .then((stream) => {
        mediaStream = stream;
        const constraits = mediaStream.getAudioTracks()[0].getConstraints();
        setCurrentAudioConstraints(constraits);
        const capabilities = mediaStream.getAudioTracks()[0].getCapabilities();
        setCurrentAudioCapabilities(capabilities);
        const settings = mediaStream.getAudioTracks()[0].getSettings();
        setCurrentAudioSettings(settings);
        // Default keep mic open turn this to false if you want to mute by default
        // mediaStream.getAudioTracks()[0].enabled = false;
      })
      .catch((e) => dispatch(LogsAction.addLog(getErrorMessage(e))));
  }, [dispatch]);

  const onServerOffer = useCallback(
    (offer: SignalData) => {
      dispatch(LogsAction.addLog('LL: ServerOffer received'));
      const peer = initPeerConnection('notInit', mediaStream);
      peer.on('signal', (signalInformation: string) => {
        window.socket.emit('Answer', signalInformation);
      });
      peer.signal(offer);
      peerClient.peer = peer;
    },
    [initPeerConnection, dispatch]
  );

  // const enableAudio = useCallback(
  //   (bool: boolean, event: string) => {
  //     dispatch(LogsAction.addLog(`enableAudio:${event} ${bool}`));
  //     mediaStream.getAudioTracks()[0].enabled = bool;
  //   },
  //   [dispatch]
  // );

  /*
   * Setup socket events
   */
  const setupWebsocket = useCallback(
    (commandId: string) => {
      window.socket.on('connect', () => {
        dispatch(LogsAction.addLog('Connected'));
      });

      window.socket.on('disconnect', () => {
        dispatch(LogsAction.addLog('Disconnected'));
      });

      window.socket.on('Log', (message: string) => {
        dispatch(LogsAction.addLog(message));
      });

      openVideoStream();

      window.socket.on('ServerOffer', onServerOffer);
      window.socket.on('ServerAnswer', (answer: SignalData) => {
        dispatch(LogsAction.addLog('LL: ServerAnswer received'));
        peerClient.isAnswered = true;
        peerClient.peer.signal(answer);
      });

      window.socket.emit('JoinRoom', commandId);
    },
    [dispatch, openVideoStream, onServerOffer]
  );

  useEffect(() => {
    const query = queryString.parse(location.search);
    const schema = yup.object().shape({
      memberId: yup.string().required(),
      deviceId: yup.string().required(),
      accessToken: yup.string().required(),
    });

    const commandBody: Command = {
      id: '',
      memberId: '',
      deviceId: '',
      type: 'STREAM',
      isDone: false,
    };

    schema
      .validate(query)
      .then((validatedQuery) => {
        Axios.defaults.headers.Authorization = validatedQuery.accessToken;
        commandBody.memberId = validatedQuery.memberId;
        commandBody.deviceId = validatedQuery.deviceId;
        const url = `${apis.MEMBER}/${validatedQuery.memberId}?access_token=${validatedQuery.accessToken}`;
        return Axios.get(url);
      })
      .then(() => dispatch(CommandsAction.create(commandBody)))
      .then((c) => setupWebsocket(c.id))
      .catch((e) => setBlockError(e.name));
  }, [location, setBlockError, dispatch, setupWebsocket]);

  if (blockError) return <p>{blockError}</p>;

  if (!command.id) return <p>Loading</p>;

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video ref={remoteVideoRef} controls />
      {/* <button
        type="button"
        className="btn btn-primary btn-lg btn-block zello"
        onTouchStart={(): void => enableAudio(true, 'onTouchStart')}
        onTouchEnd={(): void => enableAudio(false, 'onTouchEnd')}
      >
        Avaa mikki
      </button> */}

      {inDevMode ? (
        <>
          <StreamDetails
            currentAudioCapabilities={currentAudioCapabilities}
            currentAudioConstraints={currentAudioConstraints}
            currentAudioSettings={currentAudioSettings}
          />

          <div style={{ visibility: 'hidden' }}>
            <p className="muted-text">{`#${window.socket.id}`}</p>
            {inDevMode ? <LogMessages /> : ''}
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

// https://client.websocket.pet-control.vivecho.io/client/H123?deviceId=5e60df384fd0f300122cca04&memberId=5e16677f2800ea00131298f8&accessToken=S1VsMiHvFk7Gwz2pUqdDSewrK7X0tvhBJ6XuzPFmEp4r0MhK1NnaxX8yWGULsz9Y
